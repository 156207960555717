// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keycloak: {
    issuer: 'https://auth.s3qr.it/auth',
    realm: 'S3qr',
    requireHttps: true,
    clientId: 's3qr-display'
  },
  backend_getLot4AnalysisByID: "/getLot4AnalysisByID/",
  getLot4Analysis: "/getLot4Analysis",
  timeout: 10000,
  backend_voteLot4AnalysisByID: "/voteLot4AnalysisByID/",
  main_url: 'https://www.fhffcapp.it/s3edge',
  expiration: "Il prodotto scade il giorno",
  expiration_2: "di ",
  expiration_3: "del ",
  expiration_months: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novemvre",
    "Dicembre"],
  expired: "ATTENZIONE: Il prodotto ha superato la data di scadenza.",
  lab_name: "Analisi effettuata dal laboratorio ",
  max_string_tts : 150

  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-vote-pop',
  templateUrl: './vote-pop.component.html',
  styleUrls: ['./vote-pop.component.scss'],
})
export class VotePopComponent  {

  private rate: number = 0
  private voters: number = 0;
   

  constructor(
    private popover: PopoverController,
   
    private navParams: NavParams
  ) { }

  ClosePopover() {
    this.popover.dismiss();
  }

  ngOnInit() {
    console.table(this.navParams);
    this.rate = this.navParams.data.rate;
    this.voters = this.navParams.data.voters;
  }
  onRateChange($event) {
    console.log("EVent --->", $event)
    this.rate = $event;
  }

  vota() {
    this.popover.dismiss(this.rate);
  }

}
